<script>
import { required, email } from "vuelidate/lib/validators";
import { LOGIN } from "@/state/actions.type";
import { authMethods, notificationMethods } from "@/state/helpers";
import Loader from "@/components/loader";
import CryptoJS from 'crypto-js';

export default {
  components: { Loader },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      rememberMe: false,
      tryingToLogIn: false,
      privateKey: process.env.VUE_APP_REMEMBER_ME_SECRET_KEY,
      updateAvailable: process.env.VUE_APP_UPDATE_ALERT_AVAILABLE,
    };
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },

  created() {
    document.body.classList.add("auth-body-bg");

    // Check if "Remember me" cookies exist and autofill the form
    if(this.getCookie("rememberedEmail") && this.getCookie("rememberedPassword")){
      const bytesEmail = CryptoJS.AES.decrypt(this.getCookie("rememberedEmail"), this.privateKey);
      const decryptedEmail = bytesEmail.toString(CryptoJS.enc.Utf8);
      const bytesPassword = CryptoJS.AES.decrypt(this.getCookie("rememberedPassword"), this.privateKey);
      const decryptedPassword = bytesPassword.toString(CryptoJS.enc.Utf8);
      const rememberedEmail = decryptedEmail;
      const rememberedPassword = decryptedPassword;
      if (rememberedEmail && rememberedPassword) {
          this.email = rememberedEmail;
          this.password = rememberedPassword;
          this.rememberMe = true;
      }
    }
  },

  validations: {
    email: { required, email },
    password: { required }
  },

  methods: {
    ...authMethods,
    ...notificationMethods,

    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.tryingToLogIn = true;
        const rememberMeChecked = this.rememberMe;
        
        // Reset the authError if it existed.
        this.authError = null;
        return (
            this.$store.dispatch(`auth/${LOGIN}`, {
              email: this.email, password: this.password,
            })
            // eslint-disable-next-line no-unused-vars
            .then(responseData => {
              this.tryingToLogIn = false;
              this.isAuthError = false;
              var nextRoute = { name: "General Settings" };
              localStorage.setItem("updateAlert", this.updateAvailable);

              if(this.$route.query.redirectFrom && this.$route.query.redirectFrom !== '/logout') {
                nextRoute = this.$route.query.redirectFrom;
              }
              else if(responseData.permissions.includes('view-dashboard')) {
                nextRoute = { name: "Dashboard" };
              }
              this.$router.push(nextRoute);
              if (rememberMeChecked) {
                const encryptedEmail = CryptoJS.AES.encrypt(this.email, this.privateKey).toString();
                const encryptedPassword = CryptoJS.AES.encrypt(this.password, this.privateKey).toString();
                // Save email and password in cookies (you can encrypt them if you want)
                this.setCookie("rememberedEmail", encryptedEmail, 30); // Expires in 30 days
                this.setCookie("rememberedPassword", encryptedPassword, 30);
              }
            })
            .catch(error => {
              this.tryingToLogIn = false;
              this.authError = error ? error : "";
              this.isAuthError = true;
            })
        );
      }
    },
    setCookie(name, value, expirationDays) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + expirationDays);

      const cookieValue = encodeURIComponent(value) + "; expires=" + expirationDate.toUTCString() + "; domain=.portone.cloud; path=/";
      document.cookie = name + "=" + cookieValue;
    },

    getCookie(name) {
      const cookieName = name + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(";");

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }

      return "";
    },


    resetNotificationError() {
      this.$store.dispatch('notification/clear');
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <Loader v-if="tryingToLogIn === true" ></Loader>
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">

              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/portone.png" height="30" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                        <p class="text-muted">Sign in to continue to PortOne.</p>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                        @dismissed="resetNotificationError"
                      >{{notification.message}}</b-alert>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email is required.</span>
                              <span v-if="!$v.email.email">Please enter valid email.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >Password is required.</div>
                          </div>

                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                              v-model="rememberMe"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Remember me</label>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Log In</button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                            to="/register"
                            class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p>
                        <p>
							© <a href="https://portone.io/global/en" class="text-primary">PortOne SG Pte. Ltd</a>
                          <!-- Crafted with <i class="mdi mdi-heart text-danger"></i> -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <!-- <div class="bg-overlay"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
